<template>
  <div>
    <header-slot />
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['table'].refresh()"
      >
        <template #table>
          <b-table
            ref="table"
            small
            :items="tableProvider"
            :fields="fields"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(daily_pause)="data">
              {{ formatSeconds(data.value) }}
            </template>
            <template #cell(weekly_pause)="data">
              {{ formatSeconds(data.value) }}
            </template>
            <template #cell(monthly_pause)="data">
              {{ formatSeconds(data.value) }}
            </template>
            <template #cell(_actions)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  v-b-tooltip.hover.top="'Check Detail'"
                  @click="showDetail(data.item.agent_id, data.item.agent_name)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <ModalAgentDetail
      :show="infoAgentDetail.show"
      :id="infoAgentDetail.id"
      :agentName="infoAgentDetail.agentName"
      @closing="infoAgentDetail.show = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import agentAwayService from "./agent-away.service";

import ModalAgentDetail from "./components/ModalAgentDetail.vue";

export default {
  components: { ModalAgentDetail },
  data() {
    return {
      fields: [
        { key: "agent_name" },
        { key: "daily_pause" },
        { key: "weekly_pause" },
        { key: "monthly_pause" },
        { key: "_actions", label: "Actions" },
      ],
      filter: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      infoAgentDetail: {
        show: false,
        id: null,
        agentName: "",
      },
    };
  },
  computed: {},
  methods: {
    async tableProvider() {
      let body = {
        per_page: this.paginate.perPage,
        n_page: this.paginate.currentPage,
        base_date: moment().format("YYYY-MM-DD"),
        query: this.filterPrincipal.model,
      };
      let data = await agentAwayService.getPauses(body);
      this.totalRows = data.total;
      this.startPage = data.from;
      this.toPage = data.to;
      this.paginate = {
        currentPage: data.current_page,
        perPage: data.per_page,
      };

      return data.data;
    },
    formatSeconds(seconds) {
      if (!seconds) return "Without registers";
      let sec = seconds % 60;
      if (sec < 10) sec = "0" + sec;
      let min = Math.floor(seconds / 60);
      min = min % 60;
      if (min < 10) min = "0" + min;
      let hour = Math.floor(seconds / 3600);
      if (hour < 10) hour = "0" + hour;

      return `${hour} hours, ${min} minutes, ${sec} seconds`;
    },
    showDetail(id, name) {
      this.infoAgentDetail = {
        show: true,
        id: id,
        agentName: name,
      };
    },
  },
};
</script>
