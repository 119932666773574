<template>
  <b-modal
    :title="agentName + ' Pauses Detail'"
    v-model="isActive"
    @hidden="handleHidden"
    hide-footer
  >
    <b-overlay :show="isLoading" :variant="skin == 'dark' ? 'dark' : 'light'">
      <b-form-group label="Base Date">
        <b-form-datepicker v-model="baseDate" @input="getPauses" />
      </b-form-group>
      <b-form-group label="Time Period">
        <b-form-select
          :options="periodOpts"
          v-model="period"
          @change="getPauses"
        />
      </b-form-group>
      <h5 class="d-block mb-1">Pause List</h5>
      <filter-slot
        :filter-principal="{}"
        :filter="[]"
        :no-visible-principal-filter="true"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :double-paginate="false"
        @reload="getPauses()"
        @onChangeCurrentPage="onChangeCurrentPage"
      >
        <template #table>
          <b-table
            sticky-header="20rem"
            :items="pauses"
            :fields="fields"
            small
            show-empty
          >
            <template #cell(start)="data">{{
              data.value | myGlobalWithHour
            }}</template>
            <template #cell(status)="data">
              <b-badge :variant="badgeStyle(data.item.status_id)">
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(duration)="data">{{
              formatSeconds(data.value)
            }}</template>
          </b-table>
        </template>
      </filter-slot>
      <hr />
      <h4>Dead Time this {{ currentPeriod }}</h4>
      <span class="d-block mb-2 h2 text-center text-danger">{{
        formattedDeadTime
      }}</span>
      <h4>Worked Time this {{ currentPeriod }}</h4>
      <span class="d-block h2 text-center text-success">{{
        formattedWorkedTime
      }}</span>
    </b-overlay>
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import agentAwayService from "../agent-away.service";

export default {
  props: {
    show: Boolean,
    id: Number,
    agentName: String,
  },
  data: () => ({
    isActive: false,
    isLoading: false,
    periodOpts: [
      { value: 1, text: "Day" },
      { value: 2, text: "Week" },
      { value: 3, text: "Month" },
    ],
    period: 1,
    baseDate: moment().format("YYYY-MM-DD"),
    pauses: [],
    fields: [{ key: "start" }, { key: "status" }, { key: "duration" }],
    totalRows: 0,
      startPage: 0,
      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
  }),
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    totalWorkedTime(){
      if(this.pauses.length == 0) return 0;
      return this.pauses[0].total_seconds
    },
    totalDeadTime(){
      if(this.pauses.length == 0) return 0;
      return this.pauses[0].total_seconds_dead
    },
    ...mapGetters({ skin: "appConfig/skin" }),
    formattedDeadTime() {
      if (this.pauses.length == 0) return "0 hours, 00 minutes, 00 seconds";
      let total = this.totalDeadTime

      let sec = total % 60;
      if (sec < 10) sec = "0" + sec;
      let min = Math.floor(total / 60);
      min = min % 60;
      if (min < 10) min = "0" + min;
      let hour = Math.floor(total / 3600);
      if (hour < 10) hour = "0" + hour;

      return `${hour} hours, ${min} minutes, ${sec} seconds`;
    },
    formattedWorkedTime() {
      let total = this.totalWorkedTime

      let sec = total % 60;
      if (sec < 10) sec = "0" + sec;
      let min = Math.floor(total / 60);
      min = min % 60;
      if (min < 10) min = "0" + min;
      let hour = Math.floor(total / 3600);
      if (hour < 10) hour = "0" + hour;

      return `${hour} hours, ${min} minutes, ${sec} seconds`;
    },
    currentPeriod() {
      switch (this.period) {
        case 1:
          return "Day";
        case 2:
          return "Week";
        case 3:
          return "Month";
        default:
          return "Error";
      }
    },
  },
  methods: {
    handleHidden() {
      this.isActive = false;
      this.baseDate = moment().format("YYYY-MM-DD");
      this.period = 1;
      this.$emit("closing");
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
      this.getPauses();
    },
    async getPauses() {
      let body = {
        period: this.period,
        agent_id: this.id,
        base_date: this.baseDate,
        module_id: this.moduleId,
        per_page: this.paginate.perPage,
        page: this.paginate.currentPage,
      };
      this.isLoading = true;
      let data = await agentAwayService.getAgentDetail(body);
      this.pauses = data.data;
      this.totalRows = data.total;
      this.startPage = data.from;
      this.toPage = data.to;
      this.paginate = {
        currentPage: data.current_page,
        perPage: data.per_page,
      };
      this.isLoading = false;
    },
    formatSeconds(seconds) {
      if (!seconds) return "Without registers";
      let sec = seconds % 60;
      if (sec < 10) sec = "0" + sec;
      let min = Math.floor(seconds / 60);
      if (min < 10) min = "0" + min;

      return `${min} min, ${sec} sec`;
    },
    badgeStyle(status) {
      switch (status) {
        case 1:
          return "light-success";
        case 2:
          return "light-warning";
        case 3:
          return "light-danger";
        case 5:
          return "light-info";
        default:
          return "light-secondary";
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.isActive = val;
        this.getPauses();
      }
    },
  },
};
</script>